import React from "react"
import styles from "./ReportHeader.module.scss"
import CTAButton from "components/CTAButton"
import BPLogo from "images/bp-logo-white.svg"
import IthacaLogo from "images/ithaca_logo.png"
import GeorgiaLogo from "images/GA_power_h_rgb.png"
import NationalFuelLogo from "images/national_fuel_logo.png"
import MenloParkLogo from "images/city_of_menlo_park_logo.png"

const ReportHeader = reportInfo => {
  let reportEligibleLead = reportInfo?.is_report_eligible_lead
  let nationalFuelLead = reportInfo?.is_national_fuel_lead
  let georgiaLead = reportInfo?.is_ga_power_lead
  let menloParkLead = reportInfo?.is_menlo_park_lead
  let ctaHidden =
    reportEligibleLead || nationalFuelLead || georgiaLead || menloParkLead
  return (
    <div className={styles.ReportHeader} data-testid="ReportHeader">
      <div className={styles.Logos}>
        <img className={styles.BPLogo} src={BPLogo} alt="BlocPower Logo" />
        {reportEligibleLead && (
          <img
            className={styles.IthacaLogo}
            src={IthacaLogo}
            alt="Green New Deal Logo"
          />
        )}
        {nationalFuelLead && (
          <img
            className={styles.IthacaLogo}
            src={NationalFuelLogo}
            alt="National Fuel Deal Logo"
          />
        )}
        {georgiaLead && (
          <img
            className={styles.GPLogo}
            src={GeorgiaLogo}
            alt="Georgia Power Logo"
          />
        )}
        {menloParkLead && (
          <img
            className={styles.IthacaLogo}
            src={MenloParkLogo}
            alt="City of Menlo Park"
          />
        )}
      </div>
      <div className={ctaHidden ? styles.CTAHidden : ""}>
        <CTAButton styles={styles}> SCHEDULE YOUR FREE CONSULTATION </CTAButton>
      </div>
    </div>
  )
}

ReportHeader.propTypes = {}

ReportHeader.defaultProps = {}

export default ReportHeader
