import React from "react"
import PropTypes, { number } from "prop-types"
import styles from "./InMarketLandingPage.module.scss"
import { useLocation } from "react-router-dom"

const InMarketLandingPage = () => {
  const location = useLocation()

  let landingPage = process.env.REACT_APP_SUBSCRIBER_PAGE_URL
  if (location.state) {
    const buildingType = location.state.buildingType
    const numUnits = location.state.numUnits

    if (buildingType == "SINGLE_FAM" || buildingType == "SMALL_RES") {
      landingPage = process.env.REACT_APP_SF_PAGE_URL
    }
    if (["CO_OP", "MULTI_FAM"].includes(buildingType)) {
      if (numUnits > 10) {
        landingPage = process.env.REACT_APP_LMF_PAGE_URL
      } else {
        landingPage = process.env.REACT_APP_SMF_PAGE_URL
      }
    }
    if (buildingType == "HOW") {
      landingPage = process.env.REACT_APP_HOW_PAGE_URL
    }
    if (["MIXED_USE", "OTHER"].includes(buildingType)) {
      landingPage = process.env.REACT_APP_MIXED_PAGE_URL
    }
  }

  return (
    <div
      className={styles.InMarketLandingPage}
      data-testid="InMarketLandingPage"
    >
      {window.location.replace(landingPage)}
    </div>
  )
}

InMarketLandingPage.propTypes = {}

InMarketLandingPage.defaultProps = {}

export default InMarketLandingPage
