import React from "react"
import PropTypes from "prop-types"
import styles from "./FullBleedHeader.module.scss"
import BPLogo from "images/bp-logo-white.svg"

const WhiteHeader = () => (
  <div className={styles.WhiteHeader} data-testid="WhiteHeader">
    <img className={styles.Logo} src={BPLogo} alt="BlocPower Logo" />
  </div>
)

WhiteHeader.propTypes = {}

WhiteHeader.defaultProps = {}

export default WhiteHeader
