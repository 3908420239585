import React from "react"
import { Grid } from "@mui/material"
import PropTypes from "prop-types"
import styles from "./IncentivesSection.module.scss"
import Rectangle from "../../../images/metrics-rectangle.png"

const IncentivesSection = reportInfo => {
  let incentives =
    "$" + reportInfo.incentives_min + "-$" + reportInfo.incentives_max
  if (reportInfo.is_comed_lead && reportInfo.income_eligible) {
    incentives = reportInfo.building_type == "SINGLE_FAM" ? "100%" : "70%"
  }
  let incentives_text =
    "Potential Rebates from State, Local, Federal Incentives"
  if (reportInfo.is_comed_lead && reportInfo.income_eligible) {
    incentives_text =
      reportInfo.building_type == "SINGLE_FAM"
        ? "ComEd may pay up to 100% of your project costs"
        : "ComEd may pay up to 70% of your project costs"
  }
  let sectionTitle = reportInfo.is_comed_lead
    ? "Potential Project Incentives"
    : "Estimated Incentives"
  return (
    <div className={styles.IncentivesSection} data-testid="IncentivesSection">
      <div className={styles.Title}>{sectionTitle}</div>
      <div className={styles.Metrics}>{incentives}</div>

      <Grid container>
        <Grid item xs={1} md={1} lg={1}>
          <img
            src={Rectangle}
            srcSet={`${Rectangle}, ${Rectangle}`}
            alt="Saving"
            className={styles.MetricsRectangle}
          />
        </Grid>
        <Grid item xs={10} md={10} lg={10}>
          <div className={styles.MetricsDetails}>{incentives_text}</div>
        </Grid>
      </Grid>
    </div>
  )
}

IncentivesSection.propTypes = {}

IncentivesSection.defaultProps = {}

export default IncentivesSection
