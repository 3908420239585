import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import "./css/App.css"
import ReactGA from "react-ga"
import TagManager from "react-gtm-module"
import ReportPage from "./components/Report/ReportPage"
import HomePage from "./components/HomePage"
import AlternateStartPage from "./components/AlternateStartPage"
import DenverIncomeQualificationPage from "./components/DenverIncomeQualificationPage"
import DenverLandingPage from "./components/DenverLandingPage"
import ComedLandingPage from "./components/ComEdLandingPage"
import ComEdQualificationPage from "./components/ComEdQualificationPage"
import CambridgeLandingPage from "./components/CambridgeLandingPage"
import BuildingInfoPage from "./components/BuildingInfoPage"
import RequalificationPage from "./components/RequalificationPage"
import Subscriber from "./components/Subscriber"
import Waitlist from "./components/Waitlist"
import { Helmet } from "react-helmet"
import InMarketLandingPage from "components/InMarketLandingPage"

const App = () => {
  const tagManagerArgs = {
    gtmId: "GTM-NR477HS",
  }
  if (process.env.NODE_ENV == "production") {
    TagManager.initialize(tagManagerArgs)
    ReactGA.initialize("UA-67611405-11")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  const hubspotKey = process.env.REACT_APP_HUBSPOT_KEY
  return (
    <>
      {hubspotKey && (
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/20299415.js"
          ></script>
        </Helmet>
      )}
      <div>
        <Router>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/start" element={<AlternateStartPage />} />
            <Route path="verify">
              <Route path=":buildingId" element={<BuildingInfoPage />} />
            </Route>
            <Route path="requalify">
              <Route path=":buildingId" element={<RequalificationPage />} />
            </Route>
            <Route path="denvereligibility">
              <Route
                path=":buildingId"
                element={<DenverIncomeQualificationPage />}
              />
            </Route>
            <Route path="comedeligibility">
              <Route path=":buildingId" element={<ComEdQualificationPage />} />
            </Route>
            <Route path="subscriber" element={<Subscriber />} />
            <Route path="DenverLandingPage" element={<DenverLandingPage />} />
            <Route
              path="cambridgelandingpage"
              element={<CambridgeLandingPage />}
            />
            <Route path="ComedLandingPage" element={<ComedLandingPage />} />
            <Route path="waitlist" element={<Waitlist />} />
            <Route path="report">
              <Route path=":buildingId" element={<ReportPage />} />
            </Route>
            <Route path="inmarket" element={<InMarketLandingPage />} />
            <Route path="denvereligibility">
              <Route
                path=":buildingId"
                element={<DenverIncomeQualificationPage />}
              />
            </Route>
            <Route
              path="cambridgelandingpage"
              element={<CambridgeLandingPage />}
            />
            <Route path="comedeligibility">
              <Route path=":buildingId" element={<ComEdQualificationPage />} />
            </Route>
            <Route path="DenverLandingPage" element={<DenverLandingPage />} />
            <Route path="ComedLandingPage" element={<ComedLandingPage />} />
          </Routes>
        </Router>
      </div>
    </>
  )
}

App.propTypes = {}

App.defaultProps = {}

export default App
