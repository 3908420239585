import React from "react"
import CTAButton from "components/CTAButton"
import styles from "./HireBlocPower.module.scss"

const HireBlocPower = reportInfo => {
  let isCambridgeLead = reportInfo?.is_cambridge_lead
  let isComedLead = reportInfo?.is_comed_lead
  let sectionTitle = "Hire BlocPower"
  if (isCambridgeLead) {
    sectionTitle = "How projects work with BlocPower"
  }
  let amount =
    "$" +
    reportInfo.blocpower_cost_monthly_min +
    "-" +
    "$" +
    reportInfo.blocpower_cost_monthly_max
  let noHeadacheText = "BlocPower takes care of the whole process"
  let amountText = "Estimated Monthly Lease Payments"
  if (reportInfo.is_comed_lead) {
    sectionTitle = "Get Started Now"
    amount =
      "$" + reportInfo.incentives_min + "-" + "$" + reportInfo.incentives_max
    amountText = "In potential incentives available now"
    noHeadacheText = "We take care of the whole process"
  }
  if (
    reportInfo.is_comed_lead &&
    reportInfo.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo.building_type)
  ) {
    sectionTitle = "Get Started Now"
    amount = "Fully Covered Project"
    amountText =
      "Your project costs of $" +
      parseInt(reportInfo.project_cost, 10)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      " may be covered in full by ComEd"
    noHeadacheText = "We take care of the whole process"
  }

  let HireBlocPowerList = (
    <>
      <li>One point of contact to manage the entire process</li>
      <li>No money down financing with low monthly payment</li>
      <li>Ongoing maintenance included</li>
      <li>Experts ensure accurate project scope and budget</li>
      <li>
        Experts maximize your incentives to take full advantage of public
        funding
      </li>
      <li>
        Price Certainty - our monthly price includes everything<sup>*</sup> and
        is locked in
      </li>
    </>
  )
  if (reportInfo.is_comed_lead) {
    HireBlocPowerList = (
      <>
        <li>One point of contact to manage the entire process</li>
        <li>Optional no money down financing with low monthly payment</li>
        <li>Experts ensure accurate project scope and budget</li>
        <li>
          Experts maximize your incentives to take full advantage of public
          funding
        </li>
      </>
    )
  }
  if (
    reportInfo.is_comed_lead &&
    reportInfo.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo.building_type)
  ) {
    HireBlocPowerList = (
      <>
        <li>One point of contact to manage the entire process</li>
        <li>Experts ensure accurate project scope</li>
        <li>
          Experts educate you on how to maintain and operate your new equipment
        </li>
      </>
    )
  }

  return (
    <div className={styles.HireBlocPower} data-testid="HireBlocPower">
      <div className={isComedLead ? styles.CentereTitle : styles.Title}>
        {sectionTitle}
      </div>
      <div className={styles.FloatingCells}>
        <div className={styles.FloatingCell}>
          <div className={styles.FloatTitle}>{amount}</div>
          <div className={styles.FloatSubTitle}>{amountText}</div>
        </div>
        <div className={styles.FloatingCell}>
          <div className={styles.FloatTitleSmall}>NO HEADACHES!</div>
          <div className={styles.FloatSubTitle}>{noHeadacheText}</div>
        </div>
      </div>
      <div className={isComedLead ? styles.ComedListItem : styles.ListItem}>
        <ul>{HireBlocPowerList}</ul>
      </div>
      <div className={styles.FloatingCells}>
        <div className={styles.FloatingCell}>
          <CTAButton styles={styles} arrow="light">
            SCHEDULE YOUR FREE CONSULTATION
          </CTAButton>
        </div>
        {!isComedLead && (
          <div className={styles.FloatingCell}>
            <sup>*</sup> Not including changes in the project scope
          </div>
        )}
      </div>
    </div>
  )
}

HireBlocPower.propTypes = {}

HireBlocPower.defaultProps = {}

export default HireBlocPower
