import React from "react"
import PropTypes from "prop-types"
import styles from "./ComEdQualificationForm.module.scss"
import { useForm } from "react-hook-form"
import Button from "components/Button"
import { Grid } from "@mui/material"
import gridGroup from "../../images/grid-group.png"
import rectangleLine from "../../images/verify-rectangle-line.png"
import largeImage from "../../images/verify-large-image.png"

const ComEdQualificationForm = ({ onSubmit, userRole, buildingType }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUnregister: true })

  let question = (
    <>
      <p className={styles.ItalicText}>
        The answer to this question will allow us to identify potential
        incentives. If unsure, answer No and BlocPower can help you find the
        right answer in the future.
      </p>
      Do you provide housing for low income residents at the address entered?
      <p>
        <div className={styles.BoldText}>
          Select yes if your building has met any of these criteria:
        </div>
        <ul>
          <li>
            At least 50% of units rented to low income households where annual
            household income is no more than:
            <ul>
              <li>Household of 1: $58,350</li>
              <li>Household of 2: $66,700</li>
              <li>Household of 3: $75,050</li>
              <li>Household of 4: $83,350</li>
              <li>Household of 5: $90,050</li>
            </ul>
          </li>
          <li>
            Previously participated in an affordable housing program (e.g. Low
            Income Housing Tax Credit [LIHTC], Housing and Urban Development
            [HUD], local tax abatement, or the Weatherization Assistance Program
            [WAP], etc.), or other local/community-based programs for affordable
            housing and low income residents.
          </li>
        </ul>
      </p>
    </>
  )

  if (buildingType == "MULTI_FAM") {
    if (userRole == "OWNER_LIVE") {
      question = (
        <>
          <p className={styles.ItalicText}>
            The answer to this question will allow us to identify potential
            incentives. If unsure, answer No and BlocPower can help you find the
            right answer in the future.
          </p>
          Is your annual household income at or below:
          <p>
            <ul className={styles.IncomeSection}>
              <li>Household of 1: $58,350</li>
              <li>Household of 2: $66,700</li>
              <li>Household of 3: $75,050</li>
              <li>Household of 4: $83,350</li>
              <li>Household of 5: $90,050</li>
            </ul>
          </p>
        </>
      )
    }
  }

  if (buildingType == "SINGLE_FAM" || buildingType == "CONDO") {
    if (userRole == "OWNER_RENT") {
      question = (
        <>
          <p className={styles.ItalicText}>
            The answer to this question will allow us to identify potential
            incentives. If unsure, answer No and BlocPower can help you find the
            right answer in the future.
          </p>
          Is the home or building rented to a low income household where annual
          household income is no more than:
          <p>
            <ul>
              <li>Household of 1: $58,350</li>
              <li>Household of 2: $66,700</li>
              <li>Household of 3: $75,050</li>
              <li>Household of 4: $83,350</li>
              <li>Household of 5: $90,050</li>
            </ul>
          </p>
        </>
      )
    } else if (userRole == "OWNER_LIVE") {
      question = (
        <>
          <p className={styles.ItalicText}>
            The answer to this question will allow us to identify potential
            incentives. If unsure, answer No and BlocPower can help you find the
            right answer in the future.
          </p>
          Is your annual household income at or below:
          <p>
            <ul>
              <li>Household of 1: $58,350</li>
              <li>Household of 2: $66,700</li>
              <li>Household of 3: $75,050</li>
              <li>Household of 4: $83,350</li>
              <li>Household of 5: $90,050</li>
            </ul>
          </p>
        </>
      )
    } else if (userRole == "MANAGER") {
      question = (
        <>
          <p className={styles.ItalicText}>
            The answer to this question will allow us to identify potential
            incentives. If unsure, answer No and BlocPower can help you find the
            right answer in the future.
          </p>
          Is the home or building rented to a low income household where annual
          household income is no more than:
          <p>
            <ul>
              <li>Household of 1: $58,350</li>
              <li>Household of 2: $66,700</li>
              <li>Household of 3: $75,050</li>
              <li>Household of 4: $83,350</li>
              <li>Household of 5: $90,050</li>
            </ul>
          </p>
        </>
      )
    }
  }

  return (
    <div className={styles.ComEdQualificationForm}>
      <Grid container>
        <Grid item md={8} lg={8}>
          <img
            src={rectangleLine}
            srcSet={`${rectangleLine}, ${rectangleLine}`}
            alt="Family"
            className={styles.rectangleLine}
          />
          <div className={styles.FormHeading}>
            <span className={styles.FormHeadingColorPart}>Your building</span>{" "}
            information{" "}
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="ComEdQualificationForm"
          >
            <fieldset data-testid="IncomeQualificationForm">
              <div>
                <label htmlFor="comed_income_qualification"> {question} </label>
                <div>
                  <label htmlFor="comed_income_qualification">
                    <input
                      {...register("comed_income_qualification")}
                      type="radio"
                      value="yes"
                      id="comed_income_qualification"
                      isRequired
                    />
                    <span className={styles.IncomeInput}>Yes</span>
                  </label>
                </div>
                <div>
                  <label htmlFor="comed_income_qualification">
                    <input
                      {...register("comed_income_qualification", {
                        required: true,
                      })}
                      type="radio"
                      value="no"
                      id="comed_income_qualification"
                    />
                    <span className={styles.IncomeInput}>No</span>
                  </label>
                </div>
              </div>
            </fieldset>
            <div className={styles.ButtonSection}>
              <Button styles={styles}> Next </Button>
            </div>
          </form>
        </Grid>
        <Grid item md={4} lg={4}>
          <div className={styles.LargeImageSection}>
            <img
              src={gridGroup}
              srcSet={`${gridGroup}, ${gridGroup}`}
              alt="Grids"
              className={styles.GridGroupVerifyPage}
            />
            <img
              src={largeImage}
              srcSet={`${largeImage}, ${largeImage}`}
              alt="Family"
              className={styles.LargeImage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ComEdQualificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  buildingType: PropTypes.string,
}

ComEdQualificationForm.defaultProps = {
  errors: {},
  styles: {},
}

export default ComEdQualificationForm
