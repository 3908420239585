import React, { useCallback, useEffect } from "react"
import styles from "./BuildingInfoPage.module.scss"
import BuildingForm from "components/BuildingForm"
import { submitBuildingInfo } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import FullBleedHeader from "components/FullBleedHeader"
import Footer from "components/Footer"
import { useLocation } from "react-router-dom"

const BuildingInfoPage = () => {
  const location = useLocation()
  let is_ga_power_lead = false
  let is_denver_lead = false
  let is_comed_lead = false
  if (location.state) {
    is_ga_power_lead = location.state.is_ga_power_lead
    is_denver_lead = location.state.is_denver_lead
    is_comed_lead = location.state.is_comed_lead
  }
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.buildingInfo)
  const {
    is_qualified_lead: isQualifiedLead,
    is_requalify_eligible: isRequalifyEligible,
    heating_fuel_source: heatingFuelSource,
    heating_system_age: heatingSysAge,
    is_report_eligible_lead: isReportEligibleLead,
    is_in_service_market: isInServiceMarket,
    is_denver_lead: isDenverLead,
    is_denver_income_eligible: isDenverIncomeEligible,
    is_ga_single_fam: isGaSingleFam,
    is_ma_single_fam: isMaSingleFam,
    is_comed_lead: isComedLead,
    is_comed_income_eligible: isComedIncomeEligible,
    is_comed_auto_qualified: isComedAutoQualified,
    is_cambridge_report_eligible: isCambridgeReportEligible,
    is_cambridge_lead: isCambridgeLead,
    user_role: userRole,
    message,
  } = data
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { buildingId } = useParams()

  const onSubmit = useCallback(
    data => {
      if (data.heating_system_type == "NO_HEATING_SYS") {
        data["heating_fuel_source"] = "NO_HEATING_SYS"
        data["heating_system_age"] = "NO_HEATING_SYS"
      }
      if (data.heating_system_type == "ELECT_RESIST") {
        data["heating_fuel_source"] = "ELECT_RESIST"
      }
      dispatch(submitBuildingInfo(buildingId, data))
    },
    [dispatch, buildingId]
  )

  const goToReport = id => {
    navigate("../../report/" + id, { replace: true })
  }
  const goToSubscriber = () => {
    navigate("../../subscriber", { replace: true })
  }
  const goToWaitlist = () => {
    navigate("../../waitlist", { replace: true })
  }
  const goToRequalify = id => {
    navigate("../../requalify/" + id, { replace: true })
  }
  const goToInMarket = () => {
    navigate("../../inmarket", {
      state: { buildingType: data.building_type, numUnits: data.no_of_units },
    })
  }
  const goToDenverIncomeEligible = id => {
    navigate("../../denvereligibility/" + id, { replace: true })
  }
  const goToDenverLanding = () => {
    navigate("../../denverlandingpage", { replace: true })
  }
  const goToCambridgeLanding = () => {
    navigate("../../cambridgelandingpage", { replace: true })
  }
  const goToComedQualification = id => {
    navigate("../../comedeligibility/" + id, {
      state: { buildingType: data.building_type, userRole: data.user_role },
    })
  }
  const goToComedLanding = () => {
    navigate("../../comedlandingpage", { replace: true })
  }

  useEffect(() => {
    if (isError) {
      message
        ? window.alert(message)
        : window.alert("There is an error. Please try again.")
    } else if (status == "succeeded") {
      if (isCambridgeLead && isMaSingleFam) {
        goToCambridgeLanding()
        return
      }
      if (isGaSingleFam || isMaSingleFam) {
        goToWaitlist()
        return
      }
      if (isDenverLead) {
        isDenverIncomeEligible
          ? goToDenverIncomeEligible(buildingId)
          : goToDenverLanding()
      }
      if (isComedLead) {
        if (isComedAutoQualified) {
          goToReport(buildingId)
          return
        }
        if (isComedIncomeEligible) {
          goToComedQualification(buildingId)
          return
        } else if (
          ["MULTI_FAM", "CO_OP", "SINGLE_FAM", "CONDO"].includes(
            data.building_type
          ) &&
          ["TENANT"].includes(userRole)
        ) {
          goToComedLanding(buildingId)
          return
        } else if (
          ["MULTI_FAM", "CO_OP", "SINGLE_FAM", "CONDO"].includes(
            data.building_type
          ) &&
          ["OWNER_EMPTY_HOME"].includes(userRole)
        ) {
          goToReport(buildingId)
          return
        } else {
          goToWaitlist()
        }
      } else {
        if (
          isQualifiedLead ||
          isReportEligibleLead ||
          isCambridgeReportEligible
        ) {
          goToReport(buildingId)
        } else if (isRequalifyEligible) {
          goToRequalify(buildingId)
        } else if (isInServiceMarket) {
          goToInMarket()
        } else {
          if (["OLD", "PRETTY_OLD", "NO_HEATING_SYS"].includes(heatingSysAge)) {
            goToWaitlist()
          } else {
            goToSubscriber()
          }
        }
      }
    }
  }, [
    isError,
    status,
    isQualifiedLead,
    isRequalifyEligible,
    isDenverIncomeEligible,
  ])

  return (
    <div className={styles.App}>
      <FullBleedHeader />
      <div className={styles.BuildingInfoPage} data-testid="BuildingInfoPage">
        <BuildingForm
          onSubmit={onSubmit}
          is_ga_power_lead={is_ga_power_lead}
          is_denver_lead={is_denver_lead}
          is_comed_lead={is_comed_lead}
        />
      </div>
      <Footer />
    </div>
  )
}

BuildingInfoPage.propTypes = {}

BuildingInfoPage.defaultProps = {}

export default BuildingInfoPage
