import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import styles from "./BuildingForm.module.scss"
import { useForm } from "react-hook-form"
import BuildingInfoSubForm from "components/BuildingInfoSubForm"
import HvacInfoSubForm from "components/HvacInfoSubForm"
import EmailSubForm from "components/EmailSubForm"
import Button from "components/Button"
import gridGroup from "../../images/grid-group.png"
import rectangleLine from "../../images/verify-rectangle-line.png"
import largeImage from "../../images/verify-large-image.png"

const BuildingForm = ({
  onSubmit,
  is_ga_power_lead,
  is_denver_lead,
  is_comed_lead,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ shouldUnregister: true })

  return (
    <div className={styles.BuildingForm}>
      <Grid container>
        <Grid item md={8} lg={8}>
          <img
            src={rectangleLine}
            srcSet={`${rectangleLine}, ${rectangleLine}`}
            alt="Family"
            className={styles.rectangleLine}
          />
          <div className={styles.FormHeading}>
            <span className={styles.FormHeadingColorPart}>Your building</span>{" "}
            information{" "}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="BuildingForm">
            <BuildingInfoSubForm
              register={register}
              errors={errors}
              styles={styles}
              watch={watch}
              is_ga_power_lead={is_ga_power_lead}
              is_denver_lead={is_denver_lead}
              is_comed_lead={is_comed_lead}
            />
            <HvacInfoSubForm
              register={register}
              errors={errors}
              styles={styles}
              watch={watch}
              is_comed_lead={is_comed_lead}
            />
            <EmailSubForm register={register} errors={errors} styles={styles} />
            <div className={styles.ButtonSection}>
              <Button styles={styles}> Next </Button>
            </div>
          </form>
        </Grid>
        <Grid item md={4} lg={4}>
          <div className={styles.LargeImageSection}>
            <img
              src={gridGroup}
              srcSet={`${gridGroup}, ${gridGroup}`}
              alt="Grids"
              className={styles.GridGroupVerifyPage}
            />
            <img
              src={largeImage}
              srcSet={`${largeImage}, ${largeImage}`}
              alt="Family"
              className={styles.LargeImage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

BuildingForm.propTypes = {
  userSubmittedBuildingId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  is_ga_power_lead: PropTypes.bool,
  is_denver_lead: PropTypes.bool,
  is_comed_lead: PropTypes.bool,
}

BuildingForm.defaultProps = {}

export default BuildingForm
