import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportFooter.module.scss"
import FaqSection from "../FaqSection"
import TestimonialsSection from "../TestimonialsSection"
import Footer from "components/Footer"

const ReportFooter = reportInfo => (
  <div className={styles.ReportFooter} data-testid="ReportFooter">
    <FaqSection {...reportInfo} />
    <TestimonialsSection />
    <Footer />
  </div>
)

ReportFooter.propTypes = {}

ReportFooter.defaultProps = {}

export default ReportFooter
