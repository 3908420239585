import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportChallenger.module.scss"
import ReportTop from "./ReportTop"
import ReportBody from "./ReportBody"
import ReportFooter from "./ReportFooter"

const ReportChallenger = ({ reportInfo }) => (
  <div className={styles.Report} data-testid="ReportChallenger">
    <ReportTop {...reportInfo} />
    <ReportBody {...reportInfo} />
    <ReportFooter {...reportInfo} />
  </div>
)

ReportChallenger.propTypes = {
  reportInfo: PropTypes.object,
}

ReportChallenger.defaultProps = {}

export default ReportChallenger
