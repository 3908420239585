import React, { useState, useEffect } from "react"
import styles from "./AccuracyStatement.module.scss"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"

const AccuracyStatement = () => {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  useEffect(() => {
    if (window.hbspt && open) {
      window.hbspt.forms.create({
        portalId: "20299415",
        formId: "9e777388-c13c-4e28-aca9-aa9595ac4d0b",
        target: "#hubspotForm",
      })
    }
  }, [open])

  return (
    <div className={styles.AccuracyStatement} data-testid="AccuracyStatement">
      <div className={styles.SectionTitle}>
        How accurate are these estimates?
      </div>
      <div className={styles.SectionSubTitle}>
        The incentives, cost and energy bill savings estimates are predictions
        with the information we have available. To get a more accurate estimate,
        &nbsp;
        <div
          className={styles.CtaLink}
          aria-hidden="true"
          onClick={onOpenModal}
        >
          schedule a call with our expert team
        </div>
        .
        <Modal open={open} onClose={onCloseModal} center>
          <div id="hubspotForm"></div>
        </Modal>
      </div>
    </div>
  )
}

AccuracyStatement.propTypes = {}

AccuracyStatement.defaultProps = {}

export default AccuracyStatement
