import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styles from "./RequalificationPage.module.scss"
import RequalificationForm from "components/RequalificationForm"
import { useDispatch, useSelector } from "react-redux"
import { fetchReportInfo, submitRequalifyInfo } from "redux/actions"
import { useParams, useNavigate } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import FullBleedHeader from "components/FullBleedHeader"
import Footer from "components/Footer"

const RequalificationPage = () => {
  const { buildingId } = useParams()
  const dispatch = useDispatch()
  useEffect(() => dispatch(fetchReportInfo(buildingId)), [buildingId])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  let navigate = useNavigate()
  const onSubmit = useCallback(
    data => {
      setHasSubmitted(true)
      dispatch(
        submitRequalifyInfo(buildingId, {
          is_qualified_lead: !data["none_of_the_above"],
          requalification_response: Object.keys(data).filter(i => data[i]),
        })
      )
    },
    [dispatch, buildingId]
  )

  const {
    status: reqaulifyStatus,
    data: reqaulifyInfo,
    isError: isReqaulifyError,
  } = useSelector(state => state.requalifyInfo)

  useEffect(() => {
    if (!hasSubmitted) {
      return
    }
    if (isReqaulifyError) {
      message
        ? window.alert(message)
        : window.alert("There is an error. Please try again.")
    } else if (reqaulifyStatus == "succeeded") {
      const {
        is_qualified_lead: isQualifiedLead,
        is_in_core_market: isInCoreMarket,
      } = reqaulifyInfo
      if (!isQualifiedLead) {
        return goToThankYou()
      }
      if (isInCoreMarket) {
        return goToReport(buildingId)
      }
      goToInMarket()
    }
  }, [hasSubmitted, reqaulifyInfo])

  const {
    status,
    data: reportInfo,
    isError,
  } = useSelector(state => state.reportInfo)
  if (status === "loading" || status === "idle") {
    ;<LoadingScreen />
  }

  const goToReport = id => {
    navigate("../../report/" + id, { replace: true })
  }
  const goToThankYou = () => {
    navigate("../../subscriber", { replace: true })
  }
  const goToInMarket = () => {
    navigate("../../inmarket", {
      state: {
        buildingType: reportInfo.building_type,
        numUnits: reportInfo.no_of_units,
      },
    })
  }

  return (
    <div className={styles.App}>
      <FullBleedHeader />
      <div
        className={styles.RequalificationPage}
        data-testid="RequalificationPage"
      >
        <RequalificationForm onSubmit={onSubmit} buildingData={reportInfo} />
      </div>
      <Footer />
    </div>
  )
}

RequalificationPage.propTypes = {}

RequalificationPage.defaultProps = {}

export default RequalificationPage
