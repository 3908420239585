import React from "react"
import PropTypes from "prop-types"
import Select from "components/Select"
import Checkbox from "components/Checkbox"

const BuildingInfoSubForm = ({
  register,
  errors,
  styles,
  watch,
  is_ga_power_lead,
  is_denver_lead,
  is_comed_lead,
}) => {
  const building_type = watch("building_type")
  const user_role = watch("user_role")
  function NumberofUnits() {
    return (
      <>
        <label htmlFor={"no_of_units"}>Number of Units</label>
        <div className={styles.InputGroup}>
          <div className={(styles.InputGroupAddon, styles.Suffix)}>
            <input
              className={styles.SqftInput}
              id={"no_of_units"}
              type="number"
              {...register("no_of_units", {
                required: true,
                validate: {
                  number: v =>
                    parseInt(v) > 0 ||
                    "Please enter just the number for Number of Units",
                },
              })}
            />
            <span className={styles.InputGroupAddon}> Units </span>
            {errors.no_of_units && (
              <div className={styles.Error}>{errors.no_of_units.message}</div>
            )}
          </div>
        </div>
        {errors?.no_of_units?.type === "required" && (
          <span className={styles.isRequired}>
            Number of units is required.
          </span>
        )}
      </>
    )
  }
  function BuildingType() {
    return (
      <>
        <Select
          name="building_type"
          label="Building Type"
          options={building_type_options}
          errors={errors.building_type}
          register={register}
          isRequired
          styles={styles}
        />
        {errors?.building_type?.type === "required" && (
          <span className={styles.isRequired}>Building type is required.</span>
        )}
      </>
    )
  }
  function BuildingAge() {
    return (
      <Checkbox
        name="is_old_building"
        label="My Building is More Than 15 Years Old"
        errors={errors.is_old_building}
        register={register}
        styles={styles}
      />
    )
  }
  function BuildingSqFt() {
    return (
      <>
        {/* Square Footage  */}
        <label htmlFor={"square_footage"}>Square Footage</label>
        <div className={styles.InputGroup}>
          <div className={(styles.InputGroupAddon, styles.Suffix)}>
            <input
              className={styles.SqftInput}
              id={"square_footage"}
              type={"number"}
              {...register("square_footage", {
                required: true,
                valueAsNumber: true,
                validate: {
                  number: v =>
                    parseInt(v) > 0 ||
                    "Please enter just the number for Square Footage",
                },
              })}
            />
            <span className={styles.InputGroupAddon}> Sqft </span>
            {errors.square_footage && (
              <div className={styles.Error}>
                {errors.square_footage.message}
              </div>
            )}
          </div>
        </div>
        {errors?.square_footage?.type === "required" && (
          <span className={styles.isRequired}>Square Footage is required.</span>
        )}
      </>
    )
  }

  function UserRole() {
    return (
      <>
        <Select
          name="user_role"
          label="What is your relationship to this home or building?"
          options={{
            OWNER_LIVE:
              "I own this home or building and I am the sole occupant",
            TENANT: "I am a tenant in this home or building but do not own it",
            OWNER_LIVE_RENT:
              "I own this home or building and I live there and I also rent to tenants",
            OWNER_RENT:
              "I own this home or building but I don’t live there and I rent it to tenants ",
            OWNER_EMPTY_HOME: "I own this home or building and it’s unoccupied",
            MANAGER: "I manage home or building operations and maintenance",
          }}
          errors={errors.user_role}
          register={register}
          isRequired
          styles={styles}
        />
        {errors?.user_role?.type === "required" && (
          <span className={styles.isRequired}> User role is required. </span>
        )}
      </>
    )
  }

  let building_type_options = {
    SINGLE_FAM: "Single Family",
    SMALL_RES: "Small Residential (2-4 Units)",
    MULTI_FAM: "Multifamily Residential (5+ Units)",
    CO_OP: "Co-Op",
    MIXED_USE: "Mixed Use (Commercial / Residential)",
    HOW: "House of Worship / Religious Institution",
    OTHER: "Other",
  }
  if (is_ga_power_lead) {
    building_type_options = {
      SINGLE_FAM: "Single Family",
      SMALL_RES: "Small Residential (2-4 Units)",
      MULTI_FAM: "Multifamily Residential (5+ Units)",
      SMALL_COMM: "Small Commercial",
      CO_OP: "Co-Op",
      MIXED_USE: "Mixed Use (Commercial / Residential)",
      HOW: "House of Worship / Religious Institution",
      OTHER: "Other",
    }
  }
  if (is_denver_lead) {
    building_type_options = {
      SINGLE_FAM: "Single Family",
      MULTI_FAM: "Multifamily Residential (3+ Units)",
      CO_OP: "Co-Op",
      MIXED_USE: "Mixed Use (Commercial / Residential)",
      HOW: "House of Worship / Religious Institution",
      OTHER: "Other",
    }
  }
  if (is_comed_lead) {
    building_type_options = {
      SINGLE_FAM: "Single Family",
      CONDO: "Condo",
      MULTI_FAM: "Multifamily Residential (3+ Units)",
      CO_OP: "Co-Op",
      SMALL_COMM: "Small Commercial",
      MIXED_USE: "Mixed Use (Commercial / Residential)",
      HOW: "House of Worship / Religious Institution",
      OTHER: "Other",
    }
  }
  let is_comed_tenant = is_comed_lead && user_role == "TENANT"

  return (
    <fieldset data-testid="BuildingInfoSubForm">
      {is_comed_lead && <UserRole />}
      {!is_comed_tenant && <BuildingType />}
      {["MULTI_FAM", "CO_OP", "MIXED_USE", "SMALL_RES"].includes(
        building_type
      ) && <NumberofUnits />}
      {!is_comed_tenant && <BuildingAge />}
      {!is_comed_tenant && <BuildingSqFt />}
    </fieldset>
  )
}

BuildingInfoSubForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  styles: PropTypes.object,
  watch: PropTypes.func,
  is_ga_power_lead: PropTypes.bool,
  is_denver_lead: PropTypes.bool,
  is_comed_lead: PropTypes.bool,
}

BuildingInfoSubForm.defaultProps = {
  errors: {},
  styles: {},
}

export default BuildingInfoSubForm
