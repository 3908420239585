import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import styles from "./AlternateStartPage.module.scss"
import { Grid } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import TextTransition, { presets } from "react-text-transition"
import AlternateAddressWidget from "components/AlternateAddressWidget"
import { submitAddress } from "../../redux/actions"
import { useNavigate, useSearchParams } from "react-router-dom"
import WhiteHeader from "components/FullBleedHeader/WhiteHeader"
import LoadingScreen from "components/LoadingScreen"
import Footer from "components/Footer"
import gridGroup from "../../images/grid-group.png"
import family from "../../images/FamilyHome.webp"
import familyAlternate from "../../images/FamilyHome.jpg"
import solarAlternate from "../../images/solarpanel-card.png"
import waterHeaterAlternate from "../../images/heater-card.png"
import heatPump from "../../images/ashp-card.webp"
import heatPumpAlternate from "../../images/ashp-card.png"
import thermostatAlternate from "../../images/thermo-card.png"

const AlternateStartPage = () => {
  const [searchParams] = useSearchParams()
  const address = searchParams.get("address")
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.addressSubmission)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const onSubmit = useCallback(
    data => dispatch(submitAddress(data)),
    [dispatch]
  )
  useEffect(() => {
    if (address) {
      onSubmit({ address: address })
    }
  }, [address, onSubmit])
  const { message } = data
  useEffect(() => {
    if (isError) {
      window.alert(message)
    } else if (status == "succeeded") {
      navigate("../verify/" + data.data.user_submitted_building_id, {
        replace: true,
      })
    }
  }, [isError, status])
  const TEXTS = [
    "upgrade your building's heating system",
    "save on your utility bills",
    "increase your property value",
    "improve indoor comfort",
    "green your building",
    "retain your tenants",
    "improve health with indoor air quality",
    "improve resilience to heatwaves",
  ]
  const [index, setIndex] = React.useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 3000)
    return () => clearTimeout(intervalId)
  }, [])
  if (address) {
    return <LoadingScreen />
  }
  return (
    <div className={styles.BodySection}>
      <WhiteHeader />
      <div className={styles.Wrapper}>
        <Grid container>
          <Grid item md={8} lg={6}>
            <div className="Section1">
              <div className={styles.Page}>
                <div className={styles.Heading}>Own a building or a home?</div>
                <div className={styles.SubHeading}>
                  Learn how BlocPower can help you
                  <div className={styles.RotateText}>
                    <TextTransition
                      text={TEXTS[index % TEXTS.length]}
                      springConfig={presets.wobbly}
                    />
                  </div>
                </div>
                <div
                  className={styles.AddressWidget}
                  data-testid="AlternateStartPage"
                >
                  <AlternateAddressWidget onSubmit={onSubmit} />
                  <style jsx="true">{`
                    .react-mapbox-ac-input {
                      padding-left: 15px;
                      height: 40px;
                      font-family: "F37Bolton";
                      font-size: 15px;
                    }
                  `}</style>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4} lg={6}>
            <div className={styles.LargeImageSection}>
              <img
                src={gridGroup}
                srcSet={`${gridGroup}, ${gridGroup}`}
                alt="Family"
                className={styles.GridGroupAlternateStartPage}
              />
              <img
                src={family}
                srcSet={`${familyAlternate}, ${family}`}
                alt="Family"
                className={styles.LargeImage}
              />
            </div>
          </Grid>
        </Grid>
        <div className={styles.BottomContainer}>
          <Grid item container spacing={3} sm={10} md={12} lg={12}>
            <Grid item xs={5} sm={6} md={3} lg={3} order={{ md: 1, lg: 1 }}>
              <img
                src={solarAlternate}
                srcSet={`${solarAlternate} 415w, ${solarAlternate} 900w, ${solarAlternate} 1280w, ${solarAlternate} 1280w`}
                alt="Solar Panels"
                className={styles.ImageContainer}
              />
            </Grid>
            <Grid item xs={5} sm={6} md={3} lg={3} order={{ md: 3, lg: 2 }}>
              <img
                src={waterHeaterAlternate}
                srcSet={`${waterHeaterAlternate} 415w, ${waterHeaterAlternate} 900w, ${waterHeaterAlternate} 1280w, ${waterHeaterAlternate} 1280w`}
                alt="Water Heater"
                className={styles.ImageContainer}
              />
            </Grid>
            <Grid item xs={5} sm={6} md={3} lg={3} order={{ md: 2, lg: 3 }}>
              <img
                src={heatPumpAlternate}
                srcSet={`${heatPumpAlternate} 415w, ${heatPumpAlternate} 900w, ${heatPumpAlternate} 1280w, ${heatPump} 1280w`}
                alt="Air Source Heat Pump"
                className={styles.ImageContainer}
              />
            </Grid>
            <Grid item xs={5} sm={6} md={3} lg={3} order={{ md: 3, lg: 4 }}>
              <img
                srcSet={`${thermostatAlternate} 415w, ${thermostatAlternate} 900w, ${thermostatAlternate} 1280w, ${thermostatAlternate} 1280w`}
                alt="Thermostat"
                className={styles.ImageContainer}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  )
}

AlternateStartPage.propTypes = {}

AlternateStartPage.defaultProps = {}

export default AlternateStartPage
