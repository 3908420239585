import React from "react"
import PropTypes from "prop-types"
import { useForm, Controller } from "react-hook-form"
import styles from "./AlternateAddressWidget.module.scss"
import Button from "components/Button"
import MapboxAutocomplete from "react-mapbox-autocomplete"

const AlternateAddressWidget = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm()

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.AlternateAddressWidget}
      data-testid="AlternateAddressWidget"
    >
      <Controller
        render={() => (
          <MapboxAutocomplete
            publicKey={process.env.REACT_APP_MAPBOX_TOKEN}
            placeholder="ENTER BUILDING ADDRESS"
            inputClass="form-control search"
            onSuggestionSelect={result => {
              if (result.split(",").length < 4) {
                setError("address", {
                  type: "custom",
                  message: "Please select a proper street address",
                })
              } else {
                setValue("address", result)
              }
            }}
            country="us"
            resetSearch={false}
          />
        )}
        name="address"
        control={control}
        errors={errors.address}
        rules={{ required: "Please select the address." }}
      />
      {errors.address && (
        <div className={styles.Error}>{errors.address.message}</div>
      )}
      <div className={styles.NextButtonSection}>
        <Button styles={styles}> See if your building is eligible </Button>
        <style jsx="true">{`
          .Button_Button__2BYYQ {
            text-transform: none;
            font-size: 15px;
            width: 100%;
            font-family: "F37Bolton";
          }
        `}</style>
      </div>
    </form>
  )
}

AlternateAddressWidget.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

AlternateAddressWidget.defaultProps = {}

export default AlternateAddressWidget
