import React from "react"
import PropTypes from "prop-types"
import styles from "./LoadingScreen.module.scss"

const LoadingScreen = () => (
  <div className={styles.LoadingScreen} data-testid="LoadingScreen"></div>
)

LoadingScreen.propTypes = {}

LoadingScreen.defaultProps = {}

export default LoadingScreen
