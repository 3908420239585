import React, { useCallback, useEffect, useState } from "react"
import styles from "./DenverIncomeQualificationPage.module.scss"
import DenverIncomeQualificationForm from "components/DenverIncomeQualificationForm"
import { submitBuildingInfo } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import FullBleedHeader from "components/FullBleedHeader"
import Footer from "components/Footer"

const DenverIncomeQualificationPage = () => {
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.buildingInfo)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { buildingId } = useParams()

  const onSubmit = useCallback(
    data => {
      setHasSubmitted(true)
      dispatch(submitBuildingInfo(buildingId, data))
    },
    [dispatch, buildingId]
  )

  const {
    is_qualified_lead: isQualifiedLead,
    is_requalify_eligible: isRequalifyEligible,
    message,
  } = data

  const goToReport = id => {
    navigate("../../report/" + id, { replace: true })
  }
  const goToNotQualifiedLanding = () => {
    navigate("../../denverlandingpage", { replace: true })
  }
  const goToRequalify = id => {
    navigate("../../requalify/" + id, { replace: true })
  }

  useEffect(() => {
    if (!hasSubmitted) {
      return
    }
    if (isError) {
      message
        ? window.alert(message)
        : window.alert("There is an error. Please try again.")
    } else if (status == "succeeded") {
      if (isQualifiedLead) {
        goToReport(buildingId)
      } else if (isRequalifyEligible) {
        goToRequalify(buildingId)
      } else if (goToNotQualifiedLanding) {
        goToInMarket()
      }
    }
  }, [isError, status, isQualifiedLead, isRequalifyEligible])

  return (
    <div className={styles.App}>
      <FullBleedHeader />
      <div
        className={styles.DenverIncomeQualificationPage}
        data-testid="DenverIncomeQualificationPage"
      >
        <DenverIncomeQualificationForm onSubmit={onSubmit} />
      </div>
      <Footer />
    </div>
  )
}

DenverIncomeQualificationPage.propTypes = {}

DenverIncomeQualificationPage.defaultProps = {}

export default DenverIncomeQualificationPage
