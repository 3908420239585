import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportBody.module.scss"
import ProjectBenefitsSection from "../ProjectBenefitsSection"
import ImplementationOptions from "../ImplementationOptions"
import LeaseBenefitsSection from "../LeaseBenefitsSection"
import AccuracyStatement from "../AccuracyStatement"
import BlocPowerProcess from "../BlocPowerProcess"
import CTAButton from "components/CTAButton"

const ReportBody = reportInfo => {
  let isComEdIeSingleFam =
    reportInfo.is_comed_lead &&
    reportInfo.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo.building_type)
  return (
    <div className={styles.ReportBody} data-testid="ReportBody">
      <ProjectBenefitsSection />
      <ImplementationOptions {...reportInfo} />
      <AccuracyStatement />
      <BlocPowerProcess {...reportInfo} />
      {!isComEdIeSingleFam && <LeaseBenefitsSection {...reportInfo} />}
      <div className={styles.CTAButton}>
        <CTAButton styles={styles}> SCHEDULE YOUR FREE CONSULTATION </CTAButton>
      </div>
    </div>
  )
}

ReportBody.propTypes = {}

ReportBody.defaultProps = {}

export default ReportBody
