import React from "react"
import styles from "./ProjectRecommendationSection.module.scss"
import CTAButton from "components/CTAButton"

const ProjectRecommendationSection = reportInfo => {
  let isComedLead = reportInfo?.is_comed_lead
  let isComEdIESingleFam =
    reportInfo.is_comed_lead &&
    reportInfo.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo.building_type)
  let isComEdIEMultiFam =
    reportInfo.is_comed_lead &&
    reportInfo.income_eligible &&
    ["MULTI_FAM", "CO_OP"].includes(reportInfo.building_type)
  let headingText = isComEdIEMultiFam ? "big incentives " : "project savings "
  let headingSubText = isComEdIESingleFam
    ? "big incentives "
    : "$0 down financing "
  return (
    <div
      className={styles.ProjectRecommendationSection}
      data-testid="ProjectRecommendationSection"
    >
      <div className={styles.Title}>Project Recommendation Report</div>
      <div className={styles.Address}>{reportInfo.address}</div>
      <h1 className={styles.Heading}>
        Talk to an expert about&nbsp;
        <span style={{ color: "#4608F5" }}>{headingText}</span>
        &nbsp;and&nbsp;
        <span style={{ color: "#4608F5" }}>{headingSubText}</span>
      </h1>
      <div>
        <CTAButton styles={styles}>Schedule your free consultation</CTAButton>
      </div>
    </div>
  )
}

ProjectRecommendationSection.propTypes = {}

ProjectRecommendationSection.defaultProps = {}

export default ProjectRecommendationSection
