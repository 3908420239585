const intialState = {
  buildingInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  addressSubmission: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  reportInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  requalifyInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
}

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case "SubmitBuildingInfoRequested":
      return {
        ...state,
        buildingInfo: {
          ...state.buildingInfo,
          status: "loading",
          isError: false,
        },
      }
    case "SubmitBuildingInfoSucceeded":
      return {
        ...state,
        buildingInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "SubmitBuildingInfoFailed":
      return {
        ...state,
        buildingInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "RequalifyInfoRequested":
      return {
        ...state,
        requalifyInfo: {
          ...state.requalifyInfo,
          status: "loading",
          isError: false,
        },
      }
    case "RequalifyInfoSucceeded":
      return {
        ...state,
        requalifyInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "RequalifyInfoFailed":
      return {
        ...state,
        requalifyInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "AddressSubmissionRequested":
      return {
        ...state,
        addressSubmission: {
          ...state.addressSubmission,
          status: "loading",
          isError: false,
        },
      }
    case "AddressSubmissionSucceeded":
      return {
        ...state,
        addressSubmission: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "AddressSubmissionFailed":
      return {
        ...state,
        addressSubmission: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "ReportInfoRequested":
      return {
        ...state,
        reportInfo: {
          ...state.reportInfo,
          status: "loading",
          isError: false,
        },
      }
    case "ReportInfoSucceeded":
      return {
        ...state,
        reportInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "ReportInfoFailed":
      return {
        ...state,
        reportInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    default:
      return state
  }
}

export default reducer
