import React from "react"
import styles from "./DiySection.module.scss"

const DiySection = reportInfo => (
  <div className={styles.DiySection} data-testid="DiySection">
    <div className={styles.Title}>Do it yourself</div>
    <div className={styles.FloatingCells}>
      <div className={styles.FloatTitle}>
        {reportInfo.diy_month_min}&ndash;{reportInfo.diy_month_max} months
      </div>
      <div className={styles.FloatSubTitle}>
        Of your time and significant upfront cost
      </div>
    </div>
    <hr />
    <div className={styles.SubTitle}>Hire &amp; Manage:</div>
    <div className={styles.FloatingCols}>
      <>
        <ul>
          <li>Energy Engineer</li>
          <li>Auditor</li>
          <li>Construction Manager</li>
        </ul>
      </>
      <>
        <ul>
          <li>Electrician</li>
          <li>HVAC contractor</li>
          <li>Maintenance Services</li>
        </ul>
      </>
    </div>
    <hr />
    <div className={styles.SubTitle}>Administer:</div>
    <div className={styles.FloatingCols}>
      <ul>
        <li>Confirm and manage project scope and budget</li>
        <li>Research and apply for incentives</li>
        <li>Source construction permits</li>
        <li>Arrange financing with a lender</li>
      </ul>
    </div>
  </div>
)

DiySection.propTypes = {}

DiySection.defaultProps = {}

export default DiySection
