import React from "react"
import styles from "./ReportTop.module.scss"
import ReportHeader from "../ReportHeader"
import ProjectRecommendationSection from "../ProjectRecommendationSection"
import ScopeOfWorkSection from "../ScopeOfWorkSection"
import MetricsDisplay from "../MetricsDisplay"

const ReportTop = reportInfo => (
  <div className={styles.ReportTop} data-testid="ReportTop">
    <ReportHeader {...reportInfo} />
    <div className={styles.FloatingCols}>
      <div className={styles.FloatingCells}>
        <ProjectRecommendationSection {...reportInfo} />
        <ScopeOfWorkSection {...reportInfo} />
      </div>
      <div className={styles.FloatingGrow}>
        <MetricsDisplay {...reportInfo} />
      </div>
    </div>
  </div>
)

ReportTop.propTypes = {}

ReportTop.defaultProps = {}

export default ReportTop
