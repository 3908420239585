import React from "react"
import PropTypes from "prop-types"

//import Select from "react-select"

import Select from "components/Select"

const HvacInfoSubForm = ({
  register,
  errors,
  styles,
  watch,
  is_comed_lead,
}) => {
  const heatingSystemType = watch("heating_system_type")
  const user_role = watch("user_role")
  const hasExtraHeatingInputs =
    heatingSystemType && !["NO_HEATING_SYS"].includes(heatingSystemType)
  const hasNonElectricalHeatingSystem =
    heatingSystemType && !["ELECT_RESIST"].includes(heatingSystemType)
  let heating_fuel_options = {
    OIL: "Oil",
    NATURAL_GAS: "Natural Gas",
    ELECT_RESIST: "Electric Resistance",
    DUAL_FUEL: "Dual Fuel",
    OTHER: "Other",
    NO_HEATING_SYS: "I don't have a heating system",
  }
  if (is_comed_lead) {
    heating_fuel_options = {
      OIL: "Oil",
      PROPANE: "Propane",
      NATURAL_GAS: "Natural Gas",
      ELECT_RESIST: "Electric Resistance",
      DUAL_FUEL: "Dual Fuel",
      OTHER: "Other",
      NO_HEATING_SYS: "I don't have a heating system",
    }
  }
  const extraHeatingInputs = (
    <>
      {/* Heating Fuel Source */}
      {hasNonElectricalHeatingSystem && (
        <Select
          name="heating_fuel_source"
          label="Heating Fuel Source"
          options={heating_fuel_options}
          errors={errors.heating_fuel_source}
          register={register}
          isRequired
          styles={styles}
        />
      )}
      {errors?.heating_fuel_source?.type === "required" && (
        <span className={styles.isRequired}>
          Heating fuel source is required.
        </span>
      )}

      {/* Heating System Age  */}
      <Select
        name="heating_system_age"
        label="Heating System Age"
        options={{
          NEW: "New (0-3 years)",
          PRETTY_NEW: "Pretty New (4-10 years)",
          PRETTY_OLD: "Pretty Old (11-20 years)",
          OLD: "Old (21+ years)",
          NOT_SURE: "I'm not sure",
          NO_HEATING_SYS: "I don't have a heating system",
        }}
        errors={errors.heating_system_age}
        register={register}
        isRequired
        styles={styles}
      />
      {errors?.heating_system_age?.type === "required" && (
        <span className={styles.isRequired}>
          Heating system age is required.
        </span>
      )}
    </>
  )
  let is_comed_tenant = is_comed_lead && user_role == "TENANT"

  let heating_type_options = {
    STEAM: "Steam",
    HOT_WATER: "Hot Water",
    FORCED_AIR: "Forced Air",
    ELECT_RESIST: "Electric Resistance",
    HEAT_PUMP: "Heat Pump",
    NO_HEATING_SYS: "I don't have a heating system",
  }
  if (is_comed_lead) {
    heating_type_options = {
      STEAM: "Steam Boiler",
      HOT_WATER: "Hot Water Boiler",
      FORCED_AIR: "Forced Air Furnace",
      ELECT_RESIST: "Electric Resistance",
      HEAT_PUMP: "Heat Pump",
      NO_HEATING_SYS: "I don't have a heating system",
    }
  }
  function HeatingSystemType() {
    return (
      <>
        <Select
          name="heating_system_type"
          label="Heating System Type"
          options={heating_type_options}
          errors={errors.heating_system_type}
          register={register}
          isRequired
          styles={styles}
        />
        {errors?.heating_system_type?.type === "required" && (
          <span className={styles.isRequired}>
            Heating system type is required.
          </span>
        )}
      </>
    )
  }
  function CoolingSystemType() {
    return (
      <>
        <Select
          name="cooling_system_age"
          label="Cooling System Age"
          options={{
            NEW: "New (0-3 years)",
            PRETTY_NEW: "Pretty New (4-10 years)",
            PRETTY_OLD: "Pretty Old (11-20 years)",
            OLD: "Old (21+ years)",
            NOT_SURE: "I'm not sure",
            NO_COOLING_SYS: "I don't have a cooling system",
          }}
          errors={errors.cooling_system_age}
          register={register}
          isRequired
          styles={styles}
        />
        {errors?.cooling_system_age?.type === "required" && (
          <span className={styles.isRequired}>
            Cooling system age is required.
          </span>
        )}
      </>
    )
  }
  return (
    <fieldset data-testid="HvacInfoSubForm">
      {!is_comed_tenant && <HeatingSystemType />}
      {hasExtraHeatingInputs && extraHeatingInputs}
      {!is_comed_tenant && <CoolingSystemType />}
    </fieldset>
  )
}

HvacInfoSubForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  styles: PropTypes.object,
  watch: PropTypes.func,
  is_comed_lead: PropTypes.bool,
}

HvacInfoSubForm.defaultProps = {
  errors: {},
  styles: {},
}

export default HvacInfoSubForm
