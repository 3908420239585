import React from "react"
import styles from "./LeaseBenefitsSection.module.scss"
import {
  GuaranteeIcon,
  MaintenanceIconPink,
  NoLienIcon,
  NoMoneyDownIcon,
  PaymentsIcon,
  DashIcon,
} from "components/Icons"

const LeaseBenefitsSection = reportInfo => {
  let isComedLead = reportInfo?.is_comed_lead
  let leaseAmount =
    "$" +
    reportInfo.blocpower_cost_monthly_min +
    "-" +
    "$" +
    reportInfo.blocpower_cost_monthly_max
  let leaseText = "Your Estimated Monthly Lease Price"
  return (
    <div
      className={styles.LeaseBenefitsSection}
      data-testid="LeaseBenefitsSection"
    >
      <div className={styles.Title}>
        <div className={styles.SectionIcon}>
          <DashIcon />
        </div>
        BlocPower Lease Benefits
      </div>
      {isComedLead && (
        <>
          <div className={styles.LeaseSubTitle}>{leaseText}</div>
          <div className={styles.LeaseTitle}>{leaseAmount}</div>
        </>
      )}
      <div className={styles.FloatingCells}>
        <div className={styles.BenefitBox}>
          <NoMoneyDownIcon />
          <p>
            No money down
            <br />
            No loan
          </p>
        </div>
        <div className={styles.BenefitBox}>
          <PaymentsIcon />
          <p> Low, predictable, monthly payments </p>
        </div>
        <div className={styles.BenefitBox}>
          <MaintenanceIconPink />
          <p> Regular maintenance included </p>
        </div>
        <div className={styles.BenefitBox}>
          <NoLienIcon />
          <p> No lien on building required - ever </p>
        </div>
        <div className={styles.BenefitBox}>
          <GuaranteeIcon />
          <p> 15-year performance guarantee </p>
        </div>
      </div>
    </div>
  )
}

LeaseBenefitsSection.propTypes = {}

LeaseBenefitsSection.defaultProps = {}

export default LeaseBenefitsSection
