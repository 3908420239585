import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import styles from "./HomePage.module.scss"
import { useDispatch, useSelector } from "react-redux"
import TextTransition, { presets } from "react-text-transition"
import AddressCollectionWidget from "components/AddressCollectionWidget"
import { submitAddress } from "../../redux/actions"
import { useNavigate, useSearchParams } from "react-router-dom"
import FullBleedHeader from "components/FullBleedHeader"
import LoadingScreen from "components/LoadingScreen"
import Footer from "components/Footer"
import gridGroup from "../../images/grid-group.png"

const HomePage = () => {
  const [searchParams] = useSearchParams()
  const address = searchParams.get("address")
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.addressSubmission)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const onSubmit = useCallback(
    data => dispatch(submitAddress(data)),
    [dispatch]
  )
  useEffect(() => {
    if (address) {
      onSubmit({ address: address })
    }
  }, [address, onSubmit])
  const { message } = data
  useEffect(() => {
    if (isError) {
      message
        ? window.alert(message)
        : window.alert("There is an error. Please try again.")
    } else if (status == "succeeded") {
      navigate("../verify/" + data.data.user_submitted_building_id, {
        state: {
          is_ga_power_lead: data.data.is_ga_power_lead,
          is_denver_lead: data.data.is_denver_lead,
          is_comed_lead: data.data.is_comed_lead,
        },
      })
    }
  }, [isError, status])
  const TEXTS = [
    "upgrade your building's heating system",
    "save on your utility bills",
    "increase your property value",
    "improve indoor comfort",
    "green your building",
    "retain your tenants",
    "improve health with indoor air quality",
    "improve resilience to heatwaves",
  ]
  const [index, setIndex] = React.useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 3000)
    return () => clearTimeout(intervalId)
  }, [])
  if (address) {
    return <LoadingScreen />
  }
  return (
    <div className={styles.App}>
      <FullBleedHeader />
      <div className={styles.Page}>
        <div className={styles.Heading}>Own a building or a home?</div>
        <div className={styles.SubHeading}>
          Learn how BlocPower can help you
          <div className={styles.RotateText}>
            <TextTransition
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.wobbly}
              className={styles.TextTransition}
            />
          </div>
        </div>
        <div className={styles.HomePage} data-testid="HomePage">
          <AddressCollectionWidget onSubmit={onSubmit} />
        </div>
      </div>
      <img
        src={gridGroup}
        srcSet={`${gridGroup}, ${gridGroup}`}
        alt="Family"
        className={styles.GridGroupHomePage}
      />
      <Footer />
    </div>
  )
}

HomePage.propTypes = {}

HomePage.defaultProps = {}

export default HomePage
