import React from "react"
import styles from "./TestimonialsSection.module.scss"
import CTAButton from "components/CTAButton"
import Stan from "images/stan.png"

const TestimonialsSection = () => (
  <div className={styles.TestimonialsSection} data-testid="TestimonialsSection">
    <div className={styles.SectionInner}>
      <div className={styles.MobileImageContent}>
        <div className={styles.ImageFrame}>
          <img alt="Stan" src={Stan} className={styles.Image} />
        </div>
      </div>
      <div className={styles.Content}>
        <h2>
          Building upgrades can be tough. <span>We want to help.</span>
        </h2>
        <blockquote className={styles.Quote}>
          &quot;Working with BlocPower is <span>worth its weight in gold</span>.
          You don&apos;t know what you don&apos;t know about this kind of
          project, but BlocPower does.&quot;
        </blockquote>
        <div className={styles.Citation}>
          Stan, Building Owner from Brooklyn
        </div>
        <CTAButton styles={styles}>Schedule your free consultation</CTAButton>
      </div>
      <div className={styles.ImageContent}>
        <div className={styles.ImageFrame}>
          <img alt="Stan" src={Stan} className={styles.Image} />
        </div>
      </div>
    </div>
  </div>
)

TestimonialsSection.propTypes = {}

TestimonialsSection.defaultProps = {}

export default TestimonialsSection
