import React from "react"
import styles from "./ScopeOfWorkSection.module.scss"
import {
  HeatPumpIcon,
  SolarIconWhite,
  WaterHeaterIcon,
  WindowIcon,
  LightbulbIcon,
  InductionIcon,
  ElectricApplianceIcon,
  InsulationIcon,
} from "components/Icons"
import Divider from "images/icons/divider.svg"

const ScopeOfWorkSection = reportInfo => {
  let heatingSoW = "Electric Air Source Heat Pump for Heating and Cooling"
  let georgiaLead = reportInfo?.is_ga_power_lead
  let isDenverLead = reportInfo?.is_denver_lead
  let isComedLead = reportInfo?.is_comed_lead
  let isCambridgeLead = reportInfo?.is_cambridge_lead
  if (reportInfo?.is_national_fuel_lead) {
    heatingSoW = "Hybrid Heat Pump for Heating and Cooling"
  }
  return (
    <div className={styles.ScopeOfWorkSection} data-testid="ScopeOfWorkSection">
      <div className={styles.Title}>Recommended Scope of Work</div>
      <img
        src={Divider}
        alt="React Logo"
        style={{ width: "100%", margin: "0.5em 0" }}
      />
      <div className={styles.FloatingCells}>
        <div className={styles.WorkBox}>
          <HeatPumpIcon className={styles.WorkBoxIcon} />
          <p>{heatingSoW}</p>
        </div>
        <div className={styles.WorkBox}>
          <WaterHeaterIcon className={styles.WorkBoxIcon} />
          <p>Heat Pump Water Heater</p>
        </div>
        <div className={styles.WorkBox}>
          <WindowIcon className={styles.WorkBoxIcon} />
          <p>Weatherstripping for Exterior Windows</p>
        </div>
        {!reportInfo.is_report_eligible_lead && (
          <div className={styles.WorkBox}>
            <SolarIconWhite className={styles.WorkBoxIcon} />
            <p>Community Solar Subscription</p>
          </div>
        )}
        {georgiaLead && (
          <div className={styles.WorkBox}>
            <LightbulbIcon className={styles.WorkBoxIcon} />
            <p>LED Lighting</p>
          </div>
        )}
        {isDenverLead && (
          <div className={styles.WorkBox}>
            <InductionIcon className={styles.WorkBoxIcon} />
            <p>Induction Cooktop and Oven</p>
          </div>
        )}
        {isCambridgeLead && (
          <div className={styles.WorkBox}>
            <InsulationIcon className={styles.WorkBoxIcon} />
            <p>Insulation and Air Sealing</p>
          </div>
        )}
        {isComedLead && (
          <>
            <div className={styles.WorkBox}>
              <ElectricApplianceIcon className={styles.WorkBoxIcon} />
              <p>Electric Cooking and Clothes Dryer</p>
            </div>
            <div className={styles.WorkBox}>
              <InsulationIcon className={styles.WorkBoxIcon} />
              <p>Insulation and Air Sealing</p>
            </div>
            <div className={styles.WorkBox}>
              <LightbulbIcon className={styles.WorkBoxIcon} />
              <p>LED Bulbs and Water Saving Devices</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

ScopeOfWorkSection.propTypes = {}

ScopeOfWorkSection.defaultProps = {}

export default ScopeOfWorkSection
