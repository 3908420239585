import React from "react"
import PropTypes from "prop-types"
import styles from "./Select.module.scss"
import { style } from "@mui/system"

const Select = ({
  name,
  label,
  options,
  errors,
  register,
  defaultValue,
  isRequired: required,
  styles,
}) => (
  <div className={styles.InputGroup} data-testid="Select">
    <label htmlFor={name} className={styles.Error}>
      {label}
    </label>
    <select
      id={name}
      defaultValue={defaultValue}
      {...register(name, { required })}
    >
      <option value="">Select one</option>
      {Object.entries(options).map(([key, value]) => (
        <option value={key} key={key}>
          {value}
        </option>
      ))}
    </select>
    {errors && <div className={styles.Error}>{errors.message}</div>}
  </div>
)

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  styles: PropTypes.object,
  defaultValue: PropTypes.string,
}

Select.defaultProps = {
  defaultValue: null,
  options: {},
  errors: {},
  isRequired: false,
  styles: {},
}

export default Select
