import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import styles from "./RequalificationForm.module.scss"
import Checkbox from "components/Checkbox"
import Button from "components/Button"
import rectangleLine from "../../images/verify-rectangle-line.png"
import vectorLine from "../../images/Requalification-Vector-Line.png"
import largeImage from "../../images/verify-large-image.png"
import gridGroup from "../../images/grid-group.png"

const resolver = (values, context) => {
  if (!Object.values(values).includes(true)) {
    return {
      values,
      errors: {
        atLeastOne: {
          message: "Please pick at least one option.",
        },
      },
    }
  }

  return { values, errors: {} }
}

const RequalificationForm = ({ onSubmit, buildingData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver })

  const choices = [
    {
      id: "climate_care",
      name: "I care about climate and want to decarbonize",
    },
    {
      id: "unsatisfied_with_heating",
      name: "I'm not satisfied with my current heating system",
    },
    {
      id: "needs_cooling",
      name: "I'm looking for a cooling solution",
    },
    {
      id: "improve_health",
      name: "I want to improve health",
    },
    {
      id: "improve_comfort",
      name: "I want to improve comfort",
    },
    {
      id: "tenant_complaints",
      name: "My tenants are complaining about heating and/or cooling",
    },
    {
      id: "none_of_the_above",
      name: "I don't meet any of the above criteria",
    },
  ]

  // Making sure that when none_of_the_above is checked others are unchecked and vice versa
  const handleChange = event => {
    if (event.target.name === "none_of_the_above" && event.target.checked) {
      setValue("climate_care", false)
      setValue("unsatisfied_with_heating", false)
      setValue("needs_cooling", false)
      setValue("improve_health", false)
      setValue("improve_comfort", false)
      setValue("tenant_complaints", false)
    } else if (event.target.checked) {
      setValue("none_of_the_above", false)
    }
  }

  return (
    <div
      className={styles.RequalificationForm}
      data-testid="RequalificationForm"
    >
      <Grid container>
        <Grid item md={9} lg={6} xl={8}>
          <Grid container>
            <Grid item xs={1} sm={1} md={2} lg={2} xl={1}>
              <img
                src={rectangleLine}
                srcSet={`${rectangleLine}, ${rectangleLine}`}
                alt="Family"
                className={styles.rectangleLine}
              />
            </Grid>
            <Grid item xs={8} sm={10} md={9} lg={10} xl={10}>
              <div className={styles.FormHeadingBlock}>
                <span className={styles.FormHeading}>
                  We need{" "}
                  <span className={styles.FormHeadingColorPart}>
                    more information
                  </span>{" "}
                  on your building.
                </span>
              </div>
            </Grid>
          </Grid>
          <div className={styles.FormSubHeading}>
            Since you have a newer heating system, we need a bit more
            information about your goals with this project.
          </div>
          <div className={styles.CheckAllHeading}>Check all that apply</div>
          <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
            <fieldset>
              {choices.map(d => (
                <Checkbox
                  key={d.id}
                  name={d.id}
                  label={d.name}
                  register={register}
                  styles={styles}
                />
              ))}
              {errors?.atLeastOne && (
                <div className={styles.atLeastOneMessage}>
                  {errors.atLeastOne.message}
                </div>
              )}
              <div className={styles.ButtonSection}>
                <Button styles={styles}> Next </Button>
              </div>
            </fieldset>
          </form>
        </Grid>
        <Grid item md={6} lg={6} xl={4}>
          <div className={styles.LargeImageSection}>
            <img
              src={gridGroup}
              srcSet={`${gridGroup}, ${gridGroup}`}
              alt="Grids"
              className={styles.GridGroupRequalifyPage}
            />
            <img
              src={largeImage}
              srcSet={`${largeImage}, ${largeImage}`}
              alt="Family"
              className={styles.LargeImage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

RequalificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buildingData: PropTypes.object,
}

RequalificationForm.defaultProps = {}

export default RequalificationForm
