import React from "react"
import styles from "./BlocPowerProcess.module.scss"
import { PartnerForLifeIcon, ArrowRight, DashIcon } from "components/Icons"

const BlocPowerProcess = reportInfo => {
  let isComedLead = reportInfo?.is_comed_lead
  let isComEdIESingleFam =
    reportInfo.is_comed_lead &&
    reportInfo.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo.building_type)
  return (
    <div className={styles.ProcessSection} data-testid="BlocPowerProcess">
      <div className={styles.SectionTitle}>
        <div className={styles.SectionIcon}>
          <DashIcon />
        </div>
        {isComedLead ? "ComEd " : "BlocPower "}
        is your&nbsp;
        <span style={{ color: "#4608F5" }}>
          singular, trusted point of contact
        </span>
        &nbsp;through the project&apos;s completion and beyond
      </div>
      <div className={styles.StepsList}>
        <div className={styles.Step}>
          <div className={styles.Title}>Energy Assessment</div>
          <p className={styles.Content}>
            Begin with a free energy assessment to know where your building
            stands
            {!isComedLead && ", including potential fines from local laws"}.
          </p>
          <ArrowRight className={styles.Arrow} />
        </div>
        <div className={styles.Step}>
          <div className={styles.Title}>Engineering</div>
          <p className={styles.Content}>
            {isComedLead ? "Project Specialists " : "BlocPower engineers "}
            bring decades of expertise to design the optimal solution for your
            building’s unique needs.
          </p>
          <ArrowRight className={styles.Arrow} />
        </div>
        {!isComEdIESingleFam && (
          <>
            <div className={styles.Step}>
              <div className={styles.Title}>Financing</div>
              <p className={styles.Content}>
                From low interest financing, to volume-discounted procurement,
                to maximizing complex incentives—that’s our job.
              </p>
              <ArrowRight className={styles.Arrow} />
            </div>
          </>
        )}
        {isComEdIESingleFam && (
          <>
            <div className={styles.Step}>
              <div className={styles.Title}>NO-COST PROJECT</div>
              <p className={styles.Content}>
                Qualifying customers have project costs fully covered by ComEd.
              </p>
              <ArrowRight className={styles.Arrow} />
            </div>
          </>
        )}
        <div className={styles.Step}>
          <div className={styles.Title}>Construction</div>
          <p className={styles.Content}>
            Trust our vetted, job-tested, proven construction crews to get your
            project done right and on schedule.
          </p>
          {!isComEdIESingleFam && <ArrowRight className={styles.Arrow} />}
        </div>
        {!isComEdIESingleFam && (
          <>
            <div className={styles.Step}>
              <div className={styles.Title}>Partner for life</div>
              <p className={styles.Content}>
                Remote monitoring and ongoing maintenance come standard
                {isComedLead && " with financing"}, giving you one less thing to
                worry about.
              </p>
              <div className={styles.Icon}>
                <PartnerForLifeIcon />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

BlocPowerProcess.propTypes = {}

BlocPowerProcess.defaultProps = {}

export default BlocPowerProcess
