import React from "react"
import PropTypes from "prop-types"
import styles from "./Button.module.scss"
import { ButtonArraowIcon } from "components/Icons"
import { ButtonArraowLightIcon } from "components/Icons"

const Button = ({ onClick, children, styles, arrow }) => {
  let arrowIcon = <ButtonArraowIcon />
  if (arrow == "light") {
    arrowIcon = <ButtonArraowLightIcon />
  }

  return (
    <button className={styles.Button} data-testid="Button" onClick={onClick}>
      {children}
      {arrowIcon}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  arrow: PropTypes.string,
  styles: PropTypes.object,
}

Button.defaultProps = {
  arrow: "dark",
  styles: {},
}

export default Button
